import Layout from "./common/layout"
import Brand from "./channels/Brand/Brand"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ChannelsBrand = () => {
  const { currentLocale } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Brand.com, a more direct-to-consumers approach"
            : "Brand.com, tiếp cận trực tiếp đến người mua"
        }
        description={
          currentLocale === "en"
            ? "OnPoint offers brand.com, a powerful tool to help you increase your ownership of customer data and directly engage with customers along their shopping journey."
            : "OnPoint cung cấp brand.com, công cụ hiệu quả để giúp doanh nghiệp tăng quyền sở hữu dữ liệu và tương tác trực tiếp với người mua trong hành trình mua sắm."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/channel-3-meta.png"
        }
      />
      <Layout>
        <Brand />
      </Layout>
    </>
  )
}

export default ChannelsBrand
